import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactMePage = () => (
    <Layout>
        <SEO title="Contact Me" />
        <div className="px-2 md:px-20 lg:px-48 xl:px-64 pt-12 pb-24 font-sans text-green-main">
            <h1 className="text-xl text-center tracking-widest">Contact Me</h1>
            <div className="flex flex-col items-center w-full p-2 mt-4">
                <a className="flex justify-center items-center w-64 h-12 bg-green-main text-isabelline font-bold rounded-lg shadow-xl" href="mailto:admin@relativeinterest.com">admin@relativeinterest.com</a>
                <a className="flex justify-center items-center w-64 h-12 bg-green-main text-isabelline font-bold rounded-lg shadow-xl mt-8" href="tel:2086563110">(208) 656-3110</a>
            </div>
        </div>
    </Layout>
)

export default ContactMePage